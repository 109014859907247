<template>
  <div class="capture-frame">
    <div
      class="close-btn"
      @click="closeUp"
    >
      +
    </div>
    <b-row class="pt-5">
      <b-col
        md="3"
        class="pt-5"
      >
        <b-row class="pt-5 pb-1">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'outline-secondary'"
              class="mr-1 capture-btn"
              @click.prevent="showDetails()"
            >
              Video by Frames
            </b-button>
          </b-col>
        </b-row>
        <b-row class="pt-5 pb-1">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'outline-secondary'"
              class="mr-1 capture-btn"
              @click.prevent="saveToLibrary()"
            >
              Save to Activity Media Library
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'outline-secondary'"
              class="mr-1 capture-btn"
              @click.prevent="downloadFullRes"
            >
              Download Full-Res
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Jump to:"
              label-for="jumpTo"
            >
              <v-select
                id="jumpTo"
                v-model="JumpTo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="jumpToOptions"
                placeholder=""
                name="jumpTo"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="0.5">
            <span
              class="arrow-pagination"
              @click="jumpToLeft"
            >{{ leftArrow }}</span>
          </b-col>
          <b-col
            v-for="index in jumpLimit"
            :key="index"
            md="2"
            class="img-wrapper"
          >
            <div>
              <div
                class="video-content cursor-pointer"
                :class="selectedFrame === index ? 'selected-boarder' : 'unselected-boarder'"
                @click="selectTimeLine(index)"
              >
                <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + video.VideoID + '&p=' + index * 1000">
              </div>
              <div class="text-center">
                {{ timeToString(index) }}
              </div>
            </div>
          </b-col>
          <b-col md="1">
            <span
              class="arrow-pagination"
              @click="jumpToRight"
            >{{ rightArrow }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="video-container">
              <img
                class="big-img"
                :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + video.VideoID + '&p=' + selectedFrame * 1000"
              >
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    video: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      leftArrow: "<",
      rightArrow: ">",
      JumpTo: "",
      jumpToOptions: [],
      jumpLimit: [],
      selectedFrame: 0,
      jumpLimitPosition: 0,
    }
  },
  computed: {
    ...mapGetters({
      getVideoTime: "video/getVideoTime",
      getCurrentScopes: "scopes/getCurrentScopes",
    })
  },
  watch: {
    video(val) {
      this.setJumpData(val);
    },
    JumpTo(val) {
      if (val) this.setJumpLimit(this.jumpToOptions.indexOf(val) * 5 + 1)
    },
  },
  mounted() {
    this.setJumpData(this.video);
    this.setJumpLimit(1)
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Frame Image Saved',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    jumpToLeft() {
      this.JumpTo = 0;
      let position = 1;
      if (this.jumpLimitPosition <= 5) {
        position = this.jumpLimitPosition;
      } else {
        position = this.jumpLimitPosition - 5;
      }
      this.setJumpLimit(position)
    },
    jumpToRight() {
      this.JumpTo = 0;
      const timeValue = this.jumpToOptions[this.jumpToOptions.length - 1].split("-")[0]
      let position = Number(timeValue.split(":")[0]) * 60 + Number(timeValue.split(":")[1]);
      if (this.jumpLimitPosition + 5 >= position) {
        this.jumpLimitPosition = position
      } else {
        position = this.jumpLimitPosition + 5
      }
      this.setJumpLimit(position)
    },
    setJumpData(val) {
      this.jumpToOptions = [];
      const maxTime = Math.floor(val.DurationMs / 1000);
      const periods = Math.ceil(maxTime / 5);
      for (let i = 1; i <= periods; i++) {
        const period = this.timeToString(i * 5 - 4) + " - " + this.timeToString(i * 5);
        this.jumpToOptions.push(period)
      }
      this.selectedFrame = Number(this.getVideoTime.split(":")[0]) * 60 + Number(this.getVideoTime.split(":")[1]);
      setTimeout(() => {
        this.setJumpLimit(this.selectedFrame)
      }, 500)
    },
    setJumpLimit(val) {
      this.jumpLimitPosition = val;
      this.jumpLimit = [];
      for (let i = val; i <= val + 4; i++) {
        this.jumpLimit.push(i)
      }
    },
    timeToString(val) {
      let minutes = Math.floor(val / 60);
      let seconds = val - minutes * 60;
      minutes = String(minutes).length === 1 ? "0" + minutes : minutes;
      seconds = String(seconds).length === 1 ? "0" + seconds : seconds;
      return minutes + ":" + seconds;
    },

    showDetails() {
      window.open('/cases/activity/video/' + this.video.VideoID + '/' + this.video.Meta.ActivityID + '/' + this.video.DurationMs, '_blank');
    },

    saveToLibrary() {
      const postData = {
        "ActivityID": this.video.Meta.ActivityID,
        "VideoID": this.video.VideoID,
        "Ms": this.selectedFrame * 1000
      }
      apiService
          .post("file/activity/save-media-to-library", postData)
          .then(res => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'The frame image has been saved to the Activity Media Library.');
            }
          })
    },
    downloadFullRes() {
      const isLoggedIn = Number(localStorage.getItem('isLoggedIn'));
      const JWT = isLoggedIn ? "" : "?jwt=" + localStorage.getItem('JWT')
      const link = document.createElement('a');
      link.href = "/api/file/get-thumbnail/" + this.video.VideoID + "/" + this.selectedFrame * 1000 + JWT;
      link.setAttribute('download', this.video.Title + ".jpg");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeUp() {
      this.$emit("close");
    },
    selectTimeLine(val) {
      this.selectedFrame = val;
    }
  }
}
</script>

<style scoped>
#videoPlayer {
  width: 100%;
  height: 100%;
  min-height: 30rem;
}
.capture-frame {
  position: fixed;
  left: 50%;
  transform: translate(-35%, -50%);
  top: 55%;
  height: 40rem;
  width: 70rem;
  background: ghostwhite;
  border: 1px solid;
}
.close-btn {
  display: inline-block;
  transform: rotate(45deg);
  cursor: pointer;
  font-size: 2rem;
  float: right;
  margin-right: 1rem;
  color: #6E6B7B;
}
.video-content img {
  width: inherit;
  height: inherit;
}
.video-content {
  display: inline-block;
  background-color: grey;
  height: 4.5rem;
  width: 8rem;
  position: relative;
  top: -0.5rem;
}
.video-container {
  height: 30rem;
  width: 48rem;
}
.selected-boarder {
  height: inherit;
  width: calc(8rem + 3px);
  box-sizing: content-box;
  border: 2px solid indianred;
}
.unselected-boarder {
  height: inherit;
  border: 3px solid ghostwhite;
}
.img-wrapper {
  width: calc(8rem + 6px);
  margin-right: 5px;
}
.arrow-pagination {
  font-weight: bold;
  font-size: 2rem;
  line-height: 4rem;
  color: #476dae;
  cursor: pointer;
}
.big-img {
  width: inherit;
  height: inherit;
  max-width: 48rem;
  max-height: 28rem;
}
</style>